import { ar as menu } from 'src/layouts/translation/ar';
import { ar as response } from 'src/locales/response/ar';
import { ar as exception } from 'src/locales/exception/ar';

import { ar as section } from 'src/sections/translation/ar';
import { ar as auth } from 'src/sections/auth/translate/ar';

const ar = {
  roles: {
    CLUSTER_ADMIN: 'مسؤل',
    OPERATOR: 'المشغل أو العامل',
    EVALUATOR: 'المقيِّم',
    VENDOR: 'بائع',
    DONOR: 'جهات مانحة',
  },
  place_types: {
    HOSPITAL: 'مستشفى',
    RESTAURANT: 'مطعم',
    SCHOOL: 'مدرسة',
  },
  caption: {
    empty_data: 'لاتوجد بيانات',
    empty_notif: 'لم يتم العثور على إشعار',
    rows_per_page: 'صفوف لكل صفحة',
    dense: 'جدول كثيف',
    type: {
      CAMPAIGN: 'حملة',
      PROJECT: 'مشروع',
      MARKETPLACE: 'المتجر',
    },
    status: {
      approved: 'موافقة',
      finished: 'انتهى',
      pending: 'قيد الانتظار',
      rejected: 'مرفوض',
      published: 'نشرت',
      default: 'تقصير',
      APPROVED: 'موافقة',
      PENDING: 'قيد الانتظار',
      SUCCESS: 'نجاح',
      CANCELLED: 'ألغيت',
      FAILED: 'فشل',
      DECLINED: 'انخفض',
      EXPIRED: 'منتهي الصلاحية',
      REJECTED: 'مرفوض',
      DONE: 'منتهي',
      OPEN: 'يفتح',
      FORWARD: 'إلى الأمام',
      FORWARDED: 'تم إعادة توجيهها',
      CLOSED: 'مغلق',
    },
    empty_campaign: 'الحملة فارغة',
    empty_description_campaign:
      'تبدو مثل التصفية أو الفرز بيانات فارغة في قاعدة البيانات الخاصة بنانا',
    go_to_home: 'اذهب إلى المنزل',
    welcome_to_ommar: 'مرحبا بكم في ماريج',
    success_resend: 'تحقق من التحقق من البريد الإلكتروني بنجاح',
    verify_email: 'تحقق من البريد الإلكتروني',
    resend_verification: 'إعادة تقديم التحقق من البريد الإلكتروني',
    token_expired: 'انتهت صلاحية الرمز',
    verify_your_account: 'تحقق من حسابك',
    please_verify_your_email: 'يرجى التحقق من بريدك الإلكتروني',
    success_registration: 'كان تسجيلك ناجحًا',
  },
  form: {
    delete: {
      title: 'حذف',
      are_you_sure: 'هل أنت متأكد انك تريد الحذف',
      delete_button: 'حذف',
      cancel_button: 'الغاء',
    },
    global: {
      are_you_sure: 'هل أنت متأكد',
      yes: 'نعم',
      no: 'لا',
    },
  },
  notification: {
    success: {
      created_project: 'تم إنشاء المشروع',
      created_campaign: 'تم إنشاء الحملة',
      deleted_campaign: 'تم حذف الحملة',
      approveed_project: 'تمت الموافقة على المشروع',
      rejected_project: 'تم رفض المشروع',
      project_edit_request: 'تم تقديم المشروع',
      approveed_campaign: 'تمت الموافقة على الحملة',
      rejected_campaign: 'تم رفض الحملة',
      campaign_edit_request: 'تم تقديم الحملة',
      created_item: 'تم إنشاء العنصر',
      updated_item: 'تم تحديث العنصر',
      rejected_item: 'تم رفض العنصر',
      created_vendor: 'تم إنشاء البائع',
      edited_vendor: 'تم تحرير البائع',
      approve_vendor: 'تمت الموافقة على البائع',
      reject_vendor: 'تم رفض البائع',
      apply_campaign: 'تم تقديم طلبك',
      created_operator: 'تم انشاء المشغل',
      edited_operator: 'تم تعديل المشغل',
      deleted_operator: 'تم حذف المشغل',
      created_evaluator: 'تمت اضافة المقيم',
      edited_evaluator: 'تم تحديث المقيم',
      deleted_evaluator: 'تم حذف المقيم',
      approved_vendor_campaign: 'تمت الموافقة على البائع',
      rejected_vendor_campaign: 'تم رفض البائع',
      add_progress_milestone: 'تم تقديم تقدمك',
      accept_progress_milestone: 'تم قبول تقدمك',
      reject_progress_milestone: 'تم رفض تقدمك',
      add_invoice_progress: 'تم تقديم فاتورتك',
      pay_to_vendor_invoice: 'تم تقديم دفعتك لتلك الفاتورة',
      edit_account_info: 'تم تحديث الحساب الخاص بك',
      create_new_ticket: 'تم تقديم تذكرتك',
      approved_maintenance_request: 'تمت الموافقة على الطلب',
      rejected_maintenance_request: 'تم رفض الطلب',
      deleted_vendor: 'تم حذف البائع',
      applied_to_maintenance: 'تم تقديم طلبك إلى المسؤول',
      accept_evaluator_request: 'تم قبول طلب المقيِّم',
      reject_evaluator_request: 'تم رفض طلب المقيِّم',
      accept_evaluation_task: 'تم قبول مهمة التقييم',
      reject_evaluation_task: 'تم رفض مهمة التقييم',
      submit_evaluation_task: 'تم تقديم مهمتك',
      created_mosque_maintenance: 'تم إنشاء صيانة المسجد',
      campaign_task_created: 'تم إنشاء مهمة الحملة',
      campaign_task_edited: 'تم تحديث مهمة الحملة',
    },
    error: {
      has_ticket_not_finish: 'لديك تذكرة غير مكتملة بهذا النوع',
    },
  },
  auth,
  menu,
  section,
  response,
  exception,
};

export default ar;
