import { m } from 'framer-motion';
import { useMemo, useEffect } from 'react';
import { formatDistanceToNowStrict } from 'date-fns';

import {
  Box,
  Stack,
  Badge,
  Divider,
  Tooltip,
  MenuItem,
  IconButton,
  Typography,
} from '@mui/material';

import { useRouter } from 'src/routes/hooks';
import { ROOTS_TYPE, PATHS_DASHBOARD } from 'src/routes/paths';

import { useResponsive } from 'src/hooks/use-responsive';

import { useLocales, useTranslate } from 'src/locales';
import { useChatSocketContext } from 'src/socket-chat/hooks';
import { useSocketTicketContext } from 'src/socket-ticket/hooks';

// import Label from 'src/components/label';

import { UserRoleType } from 'src/auth/types';
import { useAuthContext } from 'src/auth/hooks';

import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { varHover } from 'src/components/animate';
import CustomPopover, { usePopover } from 'src/components/custom-popover';

// ----------------------------------------------------------------------

export default function NotificationsPopover() {
  const { t } = useTranslate();
  const { currentLang } = useLocales();

  const popover = usePopover();

  const { user } = useAuthContext();

  let role: string = user?.activeRole.toLowerCase();

  if (role === UserRoleType.CLUSTER_ADMIN) {
    role = 'admin';
  }

  const roleType: ROOTS_TYPE = role.toUpperCase() as ROOTS_TYPE;

  const paths = PATHS_DASHBOARD(roleType);

  const router = useRouter();

  const {
    socket,
    notifications,
    handleReadNotif,
    handleMarkAllReadNotif,
    handleJoinTicketRoom,
    listenIncomingMessage,
    listenNewNotification,
  } = useSocketTicketContext();

  const smUp = useResponsive('up', 'sm');

  const {
    socket: chatSocket,
    notifications: chatNotifications,
    // handleReadNotif: handleReadChatNotification,
    // handleMarkAllReadNotif: handleMarkAllChatReadNotif,
    // handleJoinChatRoom: handleJoinChatRoom,
    listenIncomingMessage: listenIncomingChatMessage,
    listenNewNotification: listenNewChatNotification,
  } = useChatSocketContext();

  const totalUnRead = useMemo(
    () => notifications.filter((item) => item.read_status === false).length,
    [notifications]
  );

  const allNotifications = useMemo(() => {
    // Step 1: Combine both notifications and chatNotifications into one array
    const combinedNotifications = [...notifications, ...chatNotifications];

    // Step 2: Sort the combined array (e.g., by `created_at` date)
    return combinedNotifications.sort((a, b) => {
      const dateA = new Date(a.created_at).getTime();
      const dateB = new Date(b.created_at).getTime();
      return dateB - dateA; // Sort in descending order (newest first)
    });
  }, [notifications, chatNotifications]);

  const renderHead = (
    <Stack direction="row" alignItems="center" sx={{ px: 1, py: 0.5 }}>
      <Typography
        variant="body1"
        sx={{ flexGrow: 1, fontWeight: (theme) => theme.typography.fontWeightSemiBold }}
      >
        {t('section.ticket.caption.notifications')}
      </Typography>

      {!!totalUnRead && (
        <Tooltip title="Mark all as read">
          <IconButton color="primary" size="small" onClick={handleMarkAllReadNotif}>
            <Iconify icon="eva:done-all-fill" />
          </IconButton>
        </Tooltip>
      )}

      {!smUp && (
        <IconButton onClick={popover.onClose}>
          <Iconify icon="mingcute:close-line" />
        </IconButton>
      )}
    </Stack>
  );

  useEffect(() => {
    if (chatSocket) {
      listenNewChatNotification();
      listenIncomingChatMessage();
    }
  }, [chatSocket, listenNewChatNotification, listenIncomingChatMessage]);

  useEffect(() => {
    if (socket) {
      listenNewNotification();
      listenIncomingMessage();
    }
  }, [socket, listenNewNotification, listenIncomingMessage]);

  return (
    <>
      <IconButton
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        variants={varHover(1.05)}
        color={popover.open || totalUnRead > 0 ? 'primary' : 'default'}
        onClick={popover.onOpen}
      >
        <Badge badgeContent={totalUnRead} color="error">
          <Iconify icon="solar:bell-bing-bold-duotone" width={24} />
        </Badge>
      </IconButton>

      <CustomPopover open={popover.open} onClose={popover.onClose} hiddenArrow>
        {renderHead}

        <Divider sx={{ mt: 0.25, mb: 0.5 }} />

        <Box
          sx={{
            height:
              !allNotifications.length ||
              (allNotifications.length > 0 && allNotifications.length < 4)
                ? 'auto'
                : 3.068 * 83,
            width: 325,
          }}
        >
          <Scrollbar>
            <Box sx={{ width: 325 }}>
              {allNotifications.length ? (
                allNotifications.map((notification, i) => (
                  <MenuItem
                    key={i}
                    onClick={() => {
                      handleReadNotif(notification.id);

                      if (['TICKET', 'TICKETING'].includes(notification.notif_type)) {
                        handleJoinTicketRoom(notification.room_id);

                        router.push(paths.tickets.detail_ticket(notification.ticket_id!));
                      }

                      popover.onClose();
                    }}
                  >
                    <Stack component="div" direction="column" spacing={0.25}>
                      <Stack component="div" direction="row" spacing={1} alignItems="flex-start">
                        <Iconify
                          width={22}
                          color={notification.read_status ? 'inherit' : 'secondary.main'}
                          icon={
                            (notification.specific_type === 'TICKET_CLOSED' &&
                              'solar:ticket-bold-duotone') ||
                            (notification.specific_type === 'NEW_TICKET' &&
                              'solar:ticket-sale-bold-duotone') ||
                            (notification.specific_type === 'FORWARDED_TICKET' &&
                              'solar:square-forward-bold-duotone') ||
                            (notification.notif_type === 'NEW_DONATION' &&
                              'solar:wad-of-money-bold-duotone') ||
                            'solar:chat-line-bold-duotone'
                          }
                          sx={{
                            mt: 0.3,
                            mr: '0 !important',
                          }}
                        />
                        <Stack
                          component="div"
                          direction="column"
                          spacing={0.5}
                          sx={{ maxWidth: 325, minWidth: 100 }}
                        >
                          <Typography
                            variant="body2"
                            sx={{ wordWrap: 'break-word', whiteSpace: 'normal' }}
                          >
                            {notification.title}
                          </Typography>
                          <Typography
                            variant="body2"
                            color="text.disabled"
                            sx={{ wordWrap: 'break-word', whiteSpace: 'normal' }}
                          >
                            {notification.content}
                          </Typography>
                          <Typography variant="caption" color="text.disabled">
                            {formatDistanceToNowStrict(new Date(notification.created_at), {
                              addSuffix: true,
                              locale: currentLang.adapterLocale,
                            })}
                          </Typography>
                        </Stack>
                      </Stack>
                    </Stack>
                    {!notification.read_status && (
                      <Box
                        sx={{
                          top: 15,
                          width: 6,
                          height: 6,
                          right: 20,
                          borderRadius: '50%',
                          bgcolor: 'secondary.main',
                          position: 'absolute',
                        }}
                      />
                    )}
                  </MenuItem>
                ))
              ) : (
                <Typography variant="caption" sx={{ px: 1, mb: 1 }}>
                  {t('caption.empty_notif')}
                </Typography>
              )}
            </Box>
          </Scrollbar>
        </Box>
      </CustomPopover>
    </>
  );
}
