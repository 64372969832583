import { useContext } from 'react';

import { ChatSocketContext } from '../context/chat/chat-socket-context';

// ----------------------------------------------------------------------

export const useChatSocketContext = () => {
  const context = useContext(ChatSocketContext);

  if (!context) throw new Error('useChatSocketContext context must be use inside AuthProvider');

  return context;
};
